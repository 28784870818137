.AboutUs {
  height: 100%;
  /* scroll-snap-type: y mandatory; */
  overflow: auto;
}

.AboutUs .section {
  height: 80%;
  position: relative;
  /* scroll-snap-align: start; */
}

.AboutUs .section-1 {
  height: 85%;
}

.AboutUs .section-1 .primary-image {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.AboutUs .section-1 .primary-image img {
  width: 100%;
}

.AboutUs .section-2 {
  /* height: 85%; */
  height: auto;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  /* box-sizing: border-box; */
  padding-left: 8.33%;
  padding-right: 8.33%;
  padding-top: 64px;
  padding-bottom: 64px;
}

.AboutUs .section-2 .image {
  width: 30%;
}

.AboutUs .section-2 .image img {
  width: 100%;
}

.AboutUs .section-2 .meta {
  width: 60%;
  box-sizing: border-box;
}

.AboutUs .section-2 .primary-text {
  margin-top: 40px;
  margin-bottom: 40px;
}

.AboutUs .section-2 .primary-text .h1 {
  line-height: 1.2em;
}

.AboutUs .section-2 .body-copy {
  /* margin-bottom: 16px; */
  /* margin-top: 16px; */
  padding-right: 64px;
}

.AboutUs .section-3 {
  height: 85%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.AboutUs .section-3 .primary-image {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.AboutUs .section-3 .primary-text {
  margin-bottom: 24px;
}

.AboutUs .section-3 .link {
  margin-top: 40px;
  text-decoration: underline;
}

.AboutUs .section-3 .primary-image img {
  width: 100%;
}

.AboutUs .section-4 {
  height: 100%;
  /* margin-bottom: 100px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.AboutUs .section-4.timeline-section {
  height: auto;
}

.AboutUs .section-4 .timeline-wrapper {
  /* flex: 1; */
  /* background: black; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 48px; */
}

.AboutUs .section-4 .timeline-vertical {
  display: none;
}

.AboutUs .section-4 .primary-text {
  padding-left: 8.33%;
  margin-top: 48px;
}

.AboutUs .section-5 {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 32px;
  padding-bottom: 64px;
}

.AboutUs .section-5 .article-list {
  margin-top: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
}

.AboutUs .section-5 .article-list .article {
  width: 20%;
  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 32px;
  height: 120px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.AboutUs .section-5 .article-list .article img {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .AboutUs .section-1 .primary-image img {
    height: 100%;
    width: auto;
  }

  .AboutUs .section-2 {
    position: relative;
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
  }

  .AboutUs .section-2 .meta {
    width: 100%;
  }

  .AboutUs .section-2 .image {
    position: absolute;
    top: 0;
    right: 0;
  }

  .AboutUs .section-2 .primary-text {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .AboutUs .section-2 .body-copy {
    padding-right: 0;
  }

  .AboutUs .section-2 .meta .secondary-text.bc-1 {
    width: 70%;
  }

  .AboutUs .section-3 .primary-image img {
    height: 100%;
    width: auto;
  }

  .AboutUs .section-3 .primary-text {
    text-align: center;
  }

  .AboutUs .section-3 .secondary-text {
    text-align: center;
  }

  .AboutUs .section-4 {
    height: auto;
  }

  .AboutUs .section-4 .timeline {
    display: none;
  }

  .AboutUs .section-4 .timeline-vertical {
    display: block;
  }

  .AboutUs .section-5 {
    margin-top: 32px;
  }

  .AboutUs .section-5 .article-list .article {
    width: 33%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .AboutUs .section-5 {
    height: auto;
  }
}
