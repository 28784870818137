.Category {
  height: 100%;
  /* scroll-snap-type: y mandatory; */
  overflow: auto;
}

.Category .section {
  height: 80%;
  position: relative;
  /* scroll-snap-align: start; */
}

.Category .snap-to-section {
  scroll-snap-align: start;
}

.Category .primary-image {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.Category .primary-image img {
  width: 100%;
}

.Category .blurb {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 64px;
  padding-bottom: 128px;
  box-sizing: border-box;
  padding-left: 8.33%;
  padding-right: 8.33%;
  position: relative;
  /* scroll-snap-align: start; */
  background: #efece8;
  z-index: -1;
}

.Category .blurb .subheading {
  margin-bottom: 24px;
}

.Category .blurb .body-copy {
  margin-top: 32px;
}

.Category .carousel-section {
  /* margin-top: 64px; */
  /* height: 90%; */
  /* box-sizing: border-box; */
  padding-top: 64px;
  padding-bottom: 64px;
}

.Category .corporate-img-wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.Category .corporate-img-lg {
  width: 50%;
  margin-top: -46px;
}

@media screen and (max-width: 480px) {
  .Category .primary-image img {
    height: 100%;
    width: auto;
  }

  .Category .snap-to-section img {
    width: 100%;
  }
}
