.Portfolio {
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  background: #efece8;
  padding-bottom: 128px;
}

.Portfolio .section {
  padding-top: 88px;
  padding-left: 8.33%;
  padding-right: 8.33%;
}

.Portfolio .section .h1 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.Portfolio .section .portfolio-list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
}

.Portfolio .section .portfolio-list .portfolio-list-item-wrapper {
  width: 25%;
  flex-shrink: 0;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.Portfolio .section .portfolio-list .portfolio-list-item {
  height: 240px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.Portfolio .section .portfolio-list .portfolio-list-item .image {
  flex: 1;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.Portfolio .section .portfolio-list .portfolio-list-item .image img {
  height: 100%;
  transition: all 0.2s ease-out;
  will-change: transform;
}

.Portfolio .section .portfolio-list .portfolio-list-item .label {
  height: 40px;
  background: white;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.Portfolio .section .portfolio-list .portfolio-list-item:hover .image img {
  transform: scale(1.05);
}

@media screen and (max-width: 480px) {
  .Portfolio {
    overflow: auto;
    padding-bottom: 164px;
  }

  .Portfolio .section {
    padding-bottom: 164px;
  }
  .Portfolio .section .list {
    margin-top: 8px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .Portfolio .section .list .filters {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Portfolio .section .list .filters .filter {
    margin-top: 16px;
    margin-right: 16px;
  }

  .Portfolio .section .list .body .logos {
    display: none;
    /* flex-wrap: wrap; */
  }

  /* .Portfolio .section .list .body .logos .logo {
    width: 33%;
  } */

  .Portfolio .section .list .body .items {
    margin-top: 16px;
  }

  .Portfolio .section .list .body .items .item {
    width: 50%;
    margin-top: 16px;
  }
}
