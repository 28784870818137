.Menu {
  position: absolute;
  bottom: 10%;
  left: calc(50% - 71px);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  z-index: 4;
  width: 132px;
}

.Menu .Menu-button {
  background: var(--black);
  border: solid 2px var(--white);
  color: var(--white);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 8px;
  /* width: 140px; */
  padding-left: 40px;
  padding-right: 40px;
  font-family: "Tiempos";
  font-size: 20px;
}

.Menu .Menu-button.disable {
  pointer-events: none;
}

.Menu .Menu-popup {
  position: absolute;
  height: 350px;
  top: -380px;
  background: var(--black);
  color: white;
  text-align: center;
  width: 280px;
  border-radius: 8px;
  padding-top: 32px;
  box-sizing: border-box;
  transition: all 0.2s ease-out;
  opacity: 0;
  pointer-events: none;
  font-family: "GillSans";
  letter-spacing: 1px;
  font-size: 14px;
}

.Menu .Menu-popup.open {
  opacity: 1;
  pointer-events: all;
}

.Menu .Menu-popup .Menu-group {
  margin-bottom: 32px;
  transition: all 0.2s ease-out;
}

.Menu .Menu-popup .Menu-group .heading.no-event {
  pointer-events: none;
}

.Menu .Menu-popup .Menu-group .sub-heading {
  margin-top: 4px;
  font-size: 12px;
}

.Menu .Menu-popup .Menu-group .hover-pointer:hover {
  transform: scale(1.05);
}
