.EnquiryForm-wrapper {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex; */
  /* justify-content: center;
  -ms-align-items: center; */
  /* align-items: center; */
  padding-top: 32px;
  padding-bottom: 32px;
}

.EnquiryForm {
  background: #b7bd9f;
  border-radius: 8px;
  padding: 32px;
  box-sizing: border-box;

  /* width: 40%; */
  /* position: relative; */
  margin-top: 48px;

  width: 30%;
  margin-left: 35%;
  transition: all 0.2s ease-out;
}

.EnquiryForm .title {
  color: white;
  text-align: center;
  margin-bottom: 16px;
}

.EnquiryForm .caption {
  color: white;
  text-align: center;
  margin-bottom: 16px;
}

.EnquiryForm .form {
}

.EnquiryForm .form .input-group {
  margin-bottom: 16px;
  position: relative;
}

.EnquiryForm .form .input-group .label {
  color: white;
  margin-bottom: 8px;
}

.EnquiryForm .form .input-group .label .req {
  color: red;
}

.EnquiryForm .form .input-group label {
  color: white;
  /* font-size: 12px; */
}

.EnquiryForm .form .input-group.placeholder-label input[required] + label {
  color: grey;
  position: absolute;
  left: 12px;
  top: 14px;
  font-size: 12px;
  /* left: -166px; */
}

.EnquiryForm
  .form
  .input-group.placeholder-label
  input[required]:invalid
  + label {
  display: inline-block;
}

.EnquiryForm
  .form
  .input-group.placeholder-label
  input[required]:valid
  + label {
  display: none;
}

.EnquiryForm
  .form
  .input-group.placeholder-label
  input[required]
  + label:after {
  content: "*";
  color: red;
}

.EnquiryForm .form .input-group .text-input {
  height: 40px;
  padding: 0;
  padding-left: 12px;
  width: 100%;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  outline: none;
}

.EnquiryForm .form .input-group .datetime-input {
  height: 40px;
  padding: 0;
  padding-left: 12px;
  /* width: 100%; */
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  padding-right: 8px;
  outline: none;
}

.EnquiryForm .form .input-group .textarea-input {
  height: 120px;
  padding: 0;
  padding-left: 12px;
  width: 100%;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  outline: none;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
}

.EnquiryForm .form .row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  flex-wrap: wrap;
}

.EnquiryForm .form .radio-group {
  margin-right: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.EnquiryForm .form .radio-group input {
  padding: 0;
  margin: 0;
  margin-right: 8px;
}

.EnquiryForm .form .radio-group input:hover,
.EnquiryForm .form .radio-group label:hover {
  cursor: pointer;
}

.EnquiryForm .form .options {
  margin-top: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.EnquiryForm .form .options button {
  outline: none;
}

.EnquiryForm .form .options svg {
  height: 16px;
  width: 16px;
  margin-left: auto;
  stroke: black;
}

.EnquiryForm .form .options .primary {
  font-family: "Tiempos";
  background: black;
  height: 36px;
  color: white;
  border: solid 2px white;
  border-radius: 8px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  letter-spacing: 0.4px;
}

.EnquiryForm .form .options .secondary {
  font-family: "Tiempos";
  background: none;
  height: 36px;
  color: white;
  border: none;
  /* border-radius: 8px; */
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  letter-spacing: 0.4px;
  margin-left: 16px;
}

.EnquiryForm .form .error {
  color: red;
}

@media screen and (max-width: 480px) {
  .EnquiryForm {
    width: 80%;
    margin-left: 10%;
  }

  .EnquiryForm .form .radio-group {
    width: 100%;
    margin-bottom: 8px;
  }

  .EnquiryForm .form .input-group .datetime-input {
    width: 100%;
  }
}
