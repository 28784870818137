.Enquire-button {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  border: solid 2px #a1b354;
  background: rgb(121, 123, 121);
  background: linear-gradient(
    0deg,
    rgba(121, 123, 121, 1) 5%,
    rgba(255, 255, 255, 1) 100%
  );
  font-size: 12px;
  color: #59595b;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
