.loading-images {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  z-index: 5;
}

.loading-images svg {
  height: 64px;
  width: 64px;
}
