.gallery {
  height: 80%;
  width: 80%;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.gallery .nav-btn {
  background: black;
  border-radius: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.gallery .nav-btn:hover {
  cursor: pointer;
}

.gallery .nav-btn.disabled {
  opacity: 0.5;
}

.gallery .nav-btn.disabled:hover {
  cursor: default;
}

.gallery .image-container {
  flex: 1;
  height: 100%;
  border: solid 4px black;
  border-radius: 4px;
  overflow: hidden;
  margin-left: 16px;
  margin-right: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background: black;
}

.gallery .image-container img {
  height: 100%;
}
