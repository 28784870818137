.Contact {
  position: relative;
  background: #b7bd9f;
  border-radius: 8px;
  color: whitesmoke;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding-left: 64px;
  padding-right: 64px;
  padding: 32px;
  z-index: 4;
}

.Contact .heading {
  margin-bottom: 16px;
}

.Contact .brand {
  margin-bottom: 8px;
}

.Contact .email {
  margin-bottom: 16px;
}

.Contact .map {
  margin-top: 32px;
}

@media screen and (max-width: 480px) {
  .Contact {
    width: 70%;
    text-align: center;
  }

  .Contact .map {
    width: 100%;
  }

  .Contact .map a {
    display: block;
    width: 100%;
  }

  .Contact .map a img {
    width: 100%;
  }
}
