.App {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.App .App-header {
  /* height: var(--header-height); */
  /* height: 88px; */
  /* background: black; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 2;
  transition: all 0.2s ease-out;
  /* background: transparent; */
}

.App .App-header .App-logo {
  text-decoration: none;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* margin-top: 4px; */
  /* display: block; */
}

.App .App-header .App-logo img {
  height: 55%;
  transition: all 0.2s ease-out;
}

.App .App-main {
  /* padding-top: var(--header-height); */
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.App .App-nav {
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.App .enquire-button {
  position: absolute;
  right: 64px;
  bottom: calc(10% - 20px);
}

.App .App-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  transition: all 0.2s ease-out;
  /* pointer-events: none; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.App .App-modal.open {
  opacity: 0;
  pointer-events: all;
}

.App .h1 {
  font-size: 2.857rem;
  /* line-height: 3.857rem; */
  font-family: "Sang Bleu Sunrise";
}

.App .h2 {
  font-size: 2.286rem;
  /* line-height: 3.857rem; */
  font-family: "Sang Bleu Sunrise";
}
.App .h3 {
  font-size: 2rem;
  /* line-height: 3.857rem; */
  font-family: "Sang Bleu Sunrise";
}

.App .h4 {
  font-size: 1.4rem;
  /* line-height: 3.857rem; */
  font-family: "Sang Bleu Sunrise";
}

.App .subheading {
  /* font-size: 1.16rem; */
  font-size: 1.14rem;
  font-family: "GillSans";
  letter-spacing: 0.5px;
}

.App .body-copy {
  font-size: 1.143rem;
  line-height: 1.81rem;
  font-family: "GillSans";
  letter-spacing: 0.2px;
}

.App .body-copy-small {
  font-size: 1rem;
  line-height: 1.71rem;
  font-family: "GillSans";
  letter-spacing: 0px;
}

.App .green-text {
  color: var(--green);
}

.App .mobile-text {
  display: none;
}

.App .hover-pointer:hover {
  cursor: pointer;
}

.App .show-mobile {
  display: none !important;
}

@media screen and (max-width: 480px) {
  .App .enquire-button {
    display: none;
  }

  .App .h1 {
    font-size: 1.857rem;
  }

  .App .h3 {
    font-size: 1.4rem;
  }

  .App .subheading {
    font-size: 1rem;
  }

  .App .body-copy {
    font-size: 1rem;
    line-height: 1.71rem;
  }

  .App .mobile-text {
    display: block;
  }

  .App .desktop-text {
    display: none;
  }

  .App .hide-mobile {
    display: none !important;
  }

  .App .show-mobile {
    display: block !important;
  }
}
