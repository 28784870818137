.Clients {
  height: 100%;
  overflow: hidden;
}

.Clients .section {
  padding-top: 88px;
  padding-left: 8.33%;
  padding-right: 8.33%;
}

.Clients .section .list {
  margin-top: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.Clients .section .list .filters {
  font-family: "GillSans";
  font-size: 14px;
  letter-spacing: 1px;
  width: 24%;
  flex-shrink: 0;
  /* background: black; */
}

.Clients .section .list .filters .filter {
  margin-top: 32px;
}

.Clients .section .list .filters .filter.active {
  color: var(--green);
}

.Clients .section .list .body {
  flex-grow: 1;
}

.Clients .section .list .body .logos {
  height: 104px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 32px;

  /* background: black; */
}

.Clients .section .list .body .logos .logo {
  flex: 1;
  flex-shrink: 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding-right: 32px;
  box-sizing: border-box;
}

.Clients .section .list .body .logos .logo img {
  width: 100%;
}

.Clients .section .list .body .items {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
}

.Clients .section .list .body .items .item {
  width: 25%;
  line-height: 1em;
  /* font-family: "GillSans"; */
  /* font-size: 1.167rem; */
  margin-top: 8px;
}

@media screen and (max-width: 480px) {
  .Clients {
    overflow: auto;
    padding-bottom: 164px;
  }

  .Clients .section {
    padding-bottom: 164px;
  }
  .Clients .section .list {
    margin-top: 8px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .Clients .section .list .filters {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Clients .section .list .filters .filter {
    margin-top: 16px;
    margin-right: 16px;
  }

  .Clients .section .list .body .logos {
    display: none;
    /* flex-wrap: wrap; */
  }

  /* .Clients .section .list .body .logos .logo {
    width: 33%;
  } */

  .Clients .section .list .body .items {
    margin-top: 16px;
  }

  .Clients .section .list .body .items .item {
    width: 50%;
    margin-top: 16px;
  }
}
