.timeline-container {
  margin-top: 16px;
}
.timeline-container .timeline-top {
  /* margin-bottom: 48px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 8.33%;
  padding-right: 4.33%;
}

.timeline-container .timeline-top .timeline-desc.bottom {
  display: none;
}

.timeline-container .timeline-bottom {
  /* margin-bottom: 48px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 8.33%;
  padding-right: 4.33%;
}

.timeline-container .timeline-bottom .timeline-desc.top {
  display: none;
}

.timeline-container .timeline-middle {
  margin-top: 64px;
  margin-bottom: 64px;

  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.timeline {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  height: 3px;
  background: #59595b;
  -ms-align-items: center;
  align-items: center;
  /* margin-top: 240px; */
  box-sizing: border-box;
  padding-left: 8.33%;
  padding-right: 4.33%;
  /* margin-bottom: 300px; */
  /* margin-top: 256px; */
  position: relative;
}

.timeline .progress-container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: flex-start;
}

.timeline .progress-container .progress {
  /* width: 50%; */
  height: 100%;
  background: var(--green);
}

.timeline-item-container {
  width: 14.28%;
  flex-shrink: 0;
}

.timeline-desc {
  font-family: "GillSans";
  font-size: 14px;
  color: #dedede;
  transition: all 0.2s ease-out;
  height: 100%;
}

.timeline-desc.active {
  color: #59595b;
}

.timeline-top .timeline-desc {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
}

.Timeline-item {
  position: relative;
}

.Timeline-item .item-placeholder {
  position: relative;
}

.Timeline-item .item-placeholder .item-year {
  position: absolute;

  text-align: center;
  width: 40px;
  font-family: "GillSans";
  color: var(--green);
  font-size: 24px;
}

.Timeline-item .item-placeholder .item-year.top {
  bottom: 120%;
}

.Timeline-item .item-placeholder .item-year.bottom {
  top: 120%;
}

.Timeline-item .item-placeholder .item-icon {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: solid 2px var(--green);
  box-sizing: border-box;
  background: white;
  transition: all 0.2s ease-out;
}

.Timeline-item .item-placeholder .item-icon.active {
  background: #b7bd9f;
}
