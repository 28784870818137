.Service {
  position: relative;
  background: #b7bd9f;
  border-radius: 8px;
  color: whitesmoke;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding-left: 64px;
  padding-right: 64px;
  padding: 32px;
  z-index: 4;
  width: 30%;
}

.Service .heading {
  margin-bottom: 16px;
}

.Service .subheading {
  margin-bottom: 32px;
  text-align: center;
}

.Service .modal-close {
  position: absolute;
  right: 16px;
  top: 16px;
}

@media screen and (max-width: 480px) {
  .Service {
    width: 100%;
    margin-left: 32px;
    margin-right: 32px;
  }
}
