.Home {
  height: 100%;
  /* scroll-snap-type: y mandatory; */
  overflow: auto;
  position: relative;
}

.Home img.hide {
  opacity: 0 !important;
  transition: all 0.2s ease-out;
}

.Home .section {
  height: 98%;
  scroll-snap-align: start;
}

.Home .section.section-1 {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.Home .section.section-1 .bg-img {
  position: absolute;
  width: 100%;
  z-index: -1;
  opacity: 0.95;
}

.Home .section.section-1 .meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.Home .section.section-1 .primary-text {
  /* font-size: 48px; */
  line-height: 60px;
  color: white;
  /* font-family: "Sang Bleu Sunrise"; */
}

.Home .section.section-1 .secondary-text {
  margin-top: 48px;
  color: white;
  font-size: 18px;
  /* margin-bottom: 48px; */
  /* letter-spacing: 0.7px; */
}

.Home .section.section-1 .options {
  /* margin-top: auto; */
  position: absolute;
  bottom: 6.5%;
  left: 64px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
}

.Home .section.section-1 .options .instagram-link svg {
  height: 32px;
  width: 32px;
  /* stroke: white; */
  fill: white;
}

.Home .section.section-1 .options .menu-button-container {
  margin-left: auto;
  margin-right: auto;
}

.Home .section.section-2 {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.Home .section.section-2 .text {
  width: 50%;
  height: 50%;
  box-sizing: border-box;
  padding-left: 8.33%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  /* justify-content: center; */
}

.Home .section.section-2 .text .caption {
  color: #a1b354;
  margin-bottom: 16px;
}

.Home .section.section-2 .text .link {
  margin-top: 32px;
  text-decoration: underline;
  margin-bottom: 32px;
}

.Home .section.section-2 .image {
  width: 50%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: flex-end;
  overflow: hidden;
}

.Home .section.section-2 .image img {
  /* width: 100%; */
  height: 100%;
  z-index: -1;
  opacity: 0.95;
}

.Home .section.section-3 {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.Home .section.section-3 .bg-img {
  position: absolute;
  width: 100%;
  z-index: -1;
  opacity: 0.95;
}

.Home .section.section-3 .text {
  color: white;
  font-size: 32px;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: flex-end;
  align-items: flex-end;
  padding-left: 8.33%;
  padding-right: 8.33%;
  box-sizing: border-box;
}

.Home .section.section-3 .text .mixed-text {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.Home .section.section-3 .text .mixed-text .tiempos {
  font-family: "Tiempos";
}

.Home .section.section-3 .text .mixed-text .sang-bleu {
  font-family: "Sang Bleu Sunrise";
}

.Home .section.section-3 .text .mixed-text .tiempos-italic {
  font-family: "Tiempos-Italic";
}

.Home .section.section-3 .text .mixed-text .gill-sans {
  font-family: "GillSans";
}

.Home .section.section-3 .text .mixed-text .green-text {
  color: #345b01;
}

.Home .section.section-4 {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.Home .section.section-4 .main-image {
  width: 40%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
}

.Home .section.section-4 .meta {
  width: 60%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  justify-content: center;
  padding-right: 8.33%;
}

.Home .section.section-4 .meta .wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
}

.Home .section.section-4 .meta .primary-text {
  font-family: "Tiempos-Italic";
}

.Home .section.section-4 .meta .caption {
  margin-bottom: 24px;
}

.Home .section.section-4 .meta .link {
  text-decoration: underline;
  margin-top: 32px;
  margin-bottom: 32px;
}

.Home .section.section-4 .meta .image-row {
  height: 120px;
  /* background: whitesmoke; */
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 16px;
  position: relative;
}

.Home .section.section-4 .meta .image-row .image {
  padding-right: 36px;
  padding-left: 36px;
}

.Home .section.section-4 .meta .image-row .image-moving {
  position: absolute;
  height: 100%;
  top: 0;
  will-change: transform;
  padding-right: 36px;
  padding-left: 36px;
}

.Home .section.section-4 .meta .image-row img {
  height: 120px;
}

.Home .section.section-4 .main-image img {
  /* width: 100%; */
  height: 100%;
  z-index: -1;
  opacity: 0.95;
  position: absolute;
  top: -40px;
}

.Home .section.section-5 {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  /* height: 80%; */
  min-height: 80%;
  height: auto;
  /* padding-top: 32px; */
  padding-bottom: 32px;
}

.Home .section.section-5 .wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  min-height: 75%;
  /* height: 100%; */
}

.Home .section.section-5 .primary-text {
  margin-bottom: 16px;
}

.Home .section.section-5 .offerings {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 40px;
}

.Home .section.section-5 .offerings .offering {
  /* height: 240px; */
  width: 200px;
  margin-left: 32px;
  margin-right: 32px;
}

.Home .section.section-5 .offerings .offering .offering-icon {
  height: 200px;
  width: 200px;
  border-radius: 100%;
  background: #efece8;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.Home .section.section-5 .offerings .offering .offering-icon img {
  width: 120px;
  transition: all 0.2s ease-out;
}

.Home .section.section-5 .offerings .offering .offering-desc {
  /* height: 40px; */
  margin-top: 24px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  font-family: "GillSans";
  font-size: 16px;
}

.Home .section.section-5 .offerings .offering:hover .offering-icon img {
  transform: scale(1.1);
}

.Home .section.section-5 .offerings .offering:hover .offering-desc {
  color: var(--green);
}

.Home .section.section-5 .secondary-text {
  font-size: 16px;
}

.Home .section.section-6 {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background: #efece8;
  padding-top: 64px;
  padding-bottom: 64px;
  box-sizing: border-box;
  height: auto;
}

.Home .section.section-6 .blocks {
  width: 80%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.Home .section.section-6 .blocks .block {
  width: 432px;
  height: 432px;
  /* background: white; */
  box-sizing: border-box;
  padding: 16px;
  padding-top: 0;
}

.Home .section.section-6 .blocks .block .block-image {
  height: 80%;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  /* -ms-align-items: center; */
  align-items: flex-end;
  justify-content: center;
}

.Home .section.section-6 .blocks .block .block-image img {
  width: 100%;
  transition: all 0.2s ease-out;
}

.Home .section.section-6 .blocks .block .block-desc {
  font-size: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  height: 56px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background: white;
  transition: all 0.2s ease-out;
}

.Home .section.section-6 .blocks .block:hover .block-desc {
  /* background: black; */
  color: var(--green);
}

.Home .section.section-6 .blocks .block:hover .block-image img {
  transform: scale(1.05);
}

.Home .section.section-7 {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.Home .section.section-7 .meta {
  width: 60%;
  box-sizing: border-box;
  padding-left: 8.33%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.Home .section.section-7 .meta .caption {
  margin-bottom: 16px;
}

.Home .section.section-7 .meta .primary-text {
  line-height: 48px;
  font-size: 40px;
}

.Home .section.section-7 .meta .headline {
  margin-bottom: 16px;
}

.Home .section.section-7 .image {
  width: 40%;
}

.Home .section.section-7 .image img {
  height: 100%;
  margin-top: -32px;
}

.Home .wobble1 {
  animation-name: wobble1;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

.Home .wobble2 {
  animation-name: wobble2;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

.Home .wobble3 {
  animation-name: wobble3;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

.Home .wobble4 {
  animation-name: wobble4;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

@keyframes wobble1 {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(10deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@keyframes wobble2 {
  0% {
    transform: rotateZ(15deg);
  }
  50% {
    transform: rotateZ(25deg);
  }
  100% {
    transform: rotateZ(15deg);
  }
}

@keyframes wobble3 {
  0% {
    transform: rotateZ(200deg);
  }
  50% {
    transform: rotateZ(210deg);
  }
  100% {
    transform: rotateZ(200deg);
  }
}

@keyframes wobble4 {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(10deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@media screen and (max-width: 480px) {
  .Home {
    width: 100%;
  }
  .Home .section {
    width: 100%;
  }

  .Home .section.section-1 .bg-img {
    height: 100%;
    width: auto;
  }

  .Home .section.section-2 {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    /* flex-wrap: wrap-reverse; */
  }

  .Home .section.section-3 .bg-img {
    height: 100%;
    width: auto;
  }

  .Home .section.section-1 .primary-text {
    line-height: 36px;
  }

  .Home .section.section-1 .secondary-text {
    font-size: 12px;
    text-align: center;
  }

  .Home .section.section-1 .options {
    display: none;
  }

  .Home .section.section-2 {
    position: relative;
    height: auto;
  }

  .Home .section.section-2 .text {
    width: 100%;
    padding-right: 46px;
    text-align: center;
    padding-top: 32px;
  }

  .Home .section.section-2 .image {
    width: 100%;
    height: auto;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .Home .section.section-2 .image img {
    width: 100%;
    height: auto;
  }

  .Home .section.section-3 {
    justify-content: flex-start;
  }

  .Home .section.section-3 .text {
    text-align: center;
    -ms-align-items: center;
    align-items: center;
    margin-top: 108px;
  }

  .Home .section.section-3 .text .line-1 {
    font-size: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
  }

  .Home .section.section-3 .text .line-2 {
    font-size: 19px !important;
    margin-left: 2px !important;
    line-height: 19px !important;
    /* transform: scale(0.5); */
  }

  .Home .section.section-3 .text .line-3 {
    font-size: 36px !important;
    line-height: 36px !important;
    height: 36px;
    /* transform: scale(0.5); */
  }

  .Home .section.section-3 .text .line-4 {
    font-size: 26px !important;
    line-height: 26px !important;
    /* transform: scale(0.5); */
  }

  .Home .section.section-3 .text .line-5 {
    font-size: 27.6px !important;
    line-height: 28px !important;
    /* transform: scale(0.5); */
  }

  .Home .section.section-3 .text .line-6 {
    font-size: 20px !important;
    letter-spacing: 15px !important;
    margin-right: -12px !important;
    line-height: 20px !important;
    /* transform: scale(0.5); */
  }

  .Home .section.section-3 .text .line-7 {
    font-size: 30px !important;
    line-height: 30px !important;
    /* transform: scale(0.5); */
  }

  .Home .section.section-3 .text .line-8 {
    font-size: 38px !important;
    line-height: 38px !important;
    /* transform: scale(0.5); */
  }

  .Home .section.section-3 .text .line-9 {
    font-size: 21px !important;
    line-height: 21px !important;
    /* transform: scale(0.5); */
  }

  .Home .section.section-3 .text .line-10 {
    font-size: 14px !important;
    line-height: 16px !important;
    /* transform: scale(0.5); */
  }

  .Home .section.section-3 .text .line-11 {
    font-size: 24.4px !important;
    line-height: 25px !important;
    /* transform: scale(0.5); */
  }

  .Home .section.section-4 {
    position: relative;
    height: auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .Home .section.section-4 .main-image {
    width: 100%;
  }

  .Home .section.section-4 .main-image img {
    position: relative;
    top: 0;
    width: 100%;
  }

  .Home .section.section-4 .meta {
    width: 100%;
    -ms-align-items: center;
    align-items: center;
    padding-right: 0;
    padding-top: 32px;
  }

  .Home .section.section-4 .meta .wrapper {
    -ms-align-items: center;
    align-items: center;
  }

  .Home .section.section-4 .meta .primary-text {
    text-align: right;
  }

  .Home .section.section-4 .meta .link {
    text-align: right;
  }
  .Home .section.section-4 .meta .image-row {
    /* display: none; */
    /* overflow-x: hidden; */
    /* height: auto; */
    /* overflow-x: hidden; */
    overflow: hidden;
    -ms-align-items: center;
    align-items: center;
  }

  .Home .section.section-4 .meta .image-row .image {
    width: 50%;
    box-sizing: border-box;
  }

  .Home .section.section-4 .meta .image-row img {
    height: 80px;
  }

  .Home .section.section-5 {
    height: auto;
    min-height: 80%;
  }

  .Home .section.section-5 .wrapper {
    width: 100%;
  }

  .Home .section.section-5 .offerings {
    width: 100%;
  }

  .Home .section.section-5 .primary-text {
    padding-left: 32px;
    padding-right: 32px;
    text-align: center;
  }

  .Home .section.section-5 .secondary-text {
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
  }

  .Home .section.section-5 .offerings {
    flex-wrap: wrap;
  }

  .Home .section.section-5 .offerings .offering {
    width: 50%;
    margin: 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    margin-bottom: 32px;
  }

  .Home .section.section-5 .offerings .offering .offering-desc {
    font-size: 14px;
  }

  .Home .section.section-5 .offerings .offering .offering-icon {
    width: 72px;
    height: 72px;
  }

  .Home .section.section-5 .offerings .offering .offering-icon img {
    width: 32px !important;
  }

  .Home .section.section-6 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .Home .section.section-6 .blocks {
    width: 100%;
  }

  .Home .section.section-6 .blocks .block {
    padding: 12px;
  }

  .Home .section.section-6 .blocks .block .block-image img {
    width: 100%;
    /* width: auto; */
  }

  .Home .section.section-7 {
    /* display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    flex-wrap: wrap-reverse; */
    position: relative;
    height: auto;
    min-height: 80%;
  }

  .Home .section.section-7 .image {
    width: 20%;
    position: absolute;
    top: 0;
    right: 0;

    /* width: 100% !important; */
  }

  .Home .section.section-7 .image img {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .Home .section.section-7 .meta {
    /* width: 100%; */
    width: 100%;
    padding-top: 64px;
    padding-bottom: 64px;
    padding-right: 8.33%;
  }

  .Home .section.section-7 .meta .primary-text {
    font-size: 1.857rem;
    line-height: 2rem;
  }
}
