.carousel-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-wrapper .slides-wrapper {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  position: absolute;
  transition: transform 0.4s ease;
}

.carousel-wrapper .slides-wrapper .slide {
  height: 100%;
  margin-left: 32px;
  margin-right: 32px;
}

.carousel-wrapper .slides-wrapper .slide img {
  height: 100%;
}

.carousel-wrapper-new {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-wrapper-new .scroll-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-wrapper-new .slides-wrapper {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  position: absolute;
  will-change: transform;
  transition: transform 0.4s ease;
}

.carousel-wrapper-new .slides-wrapper .slide {
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;
}

.carousel-wrapper-new .slides-wrapper .slide img {
  height: 100%;
}

.carousel-wrapper-new .left-arrow {
  position: absolute;
  left: 32px;
  top: 48%;
  height: 32px;
  width: 32px;
  background: black;
  border-radius: 100%;
  box-sizing: border-box;
}

.carousel-wrapper-new .right-arrow {
  position: absolute;
  right: 32px;
  top: 48%;
  height: 32px;
  width: 32px;
  background: black;
  border-radius: 100%;
  box-sizing: border-box;
}
