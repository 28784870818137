html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: #59595b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;

  --header-height: 48px;
  --black: #000000;
  --white: #ffffff;
  --green: #a1b354;
}

@font-face {
  font-family: "Tiempos";
  src: url("./fonts/Tiempos-Regular.otf");
  /* src: local("Tiempos"), url(./fonts/Tiempos-Regular.otf) format("otf"); */
}

@font-face {
  font-family: "Tiempos-Italic";
  src: url("./fonts/Tiempos-Italic.otf");
  font-style: italic;
}

@font-face {
  font-family: "Sang Bleu Sunrise";
  src: url("./fonts/SangBleuSunrise-Regular.woff");
}

@font-face {
  font-family: "GillSans";
  src: url("./fonts/GillSans.ttc");
  /* src: local("GillSans"), url(./fonts/GillSans.ttc) format("ttc"); */
}
