.timeline-vertical {
  padding-left: 4.33%;
  padding-right: 4.33%;
  position: relative;
}

.timeline-vertical .progress-bar {
  height: 97%;
  width: 4px;
  background: black;
  position: absolute;
  top: 0;
  left: 35px;
  z-index: -1;
}

.timeline-vertical .progress-bar .progress {
  height: 0%;
  background: var(--green);
}

.timeline-vertical-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-bottom: 12px;
}

.timeline-vertical-item .icon {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: solid 2px var(--green);
  box-sizing: border-box;
  background: white;
  transition: all 0.2s ease-out;
  flex-shrink: 0;
}

.timeline-vertical-item .icon.active {
  background: #b7bd9f;
}

.timeline-vertical-item .meta {
  padding-left: 4.33%;
}

.timeline-vertical-item .meta .year {
  font-family: "GillSans";
  color: var(--green);
  font-size: 24px;
  margin-bottom: 8px;
}

.timeline-vertical-item .meta .desc {
}
