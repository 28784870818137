.App-footer {
  background: #dedede;
  scroll-snap-align: end;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 8.33%;
  padding-right: 8.33%;
  padding-top: 24px;
  padding-bottom: 24px;
}

.App-footer .meta {
  flex: 1;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.App-footer .meta .options {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.App-footer .meta .options .option {
  margin-right: 32px;
  font-size: 1rem;
}

.App-footer .meta .social-links {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: auto;
}

.App-footer .meta .social-links .social-link {
  margin-right: 16px;
}

.App-footer .meta .social-links .social-link svg {
  height: 32px;
  width: 32px;
  fill: #59595b;
}

.App-footer .subscribe {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.App-footer .subscribe .text-input {
  display: inline-block;
  margin-top: 16px;
  margin-bottom: 16px;
}

.App-footer .subscribe .text-input input {
  height: 32px;
  padding: none;
  width: 240px;
  border: none;
  box-sizing: border-box;
  padding-left: 8px;
  font-family: "GillSans";
  border-radius: 4px;
}

.App-footer .subscribe .desc {
  font-size: 1rem;
}

.App-footer .masthead {
  font-size: 1rem;
}

.App-footer .subscribe .button {
  width: 100px;
  height: 32px;
  background: #59595b;
  border: none;
  border-radius: 4px;
  font-family: "GillSans";
  color: white;
}

@media screen and (max-width: 480px) {
  .App-footer {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 12px;
    /* flex-wrap: wrap-reverse; */
  }
  .App-footer .meta .options {
    /* display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; */
  }

  .App-footer .meta .options .option {
    font-size: 1rem;
  }

  .App-footer .meta .social-links {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .App-footer .meta .social-links .social-link svg {
    height: 24px;
    width: 24px;
  }

  .App-footer .masthead {
    font-size: 1rem;
  }

  .App-footer .subscribe {
    margin-top: 8px;
  }

  .App-footer .subscribe .desc {
    font-size: 1rem;
  }

  .App-footer .subscribe .text-input {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
